<template>
  <main>
      <h1>Coming Soon</h1>
  </main>
</template>

<script>
export default {
  name: "MatricLiveWeb",
}
  
</script>

<style scoped>
    h1 {
        text-align: center;
        font-size: xxx-large;
        padding: 18%;
    }
</style>
